import React,{useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { graphql, useStaticQuery } from 'gatsby';
import aboutStepImg from '../../images/about-step.png';

const TasacionSale = () => {
    useEffect(() => {
        AOS.init({ duration : 2000});
    }, [])

    const { realEstate } = useStaticQuery(graphql`
     query {
         realEstate {
             sections{
                 about{
                    commitment{
                        title
                        paragraph
                    }
                 }

             }
         }
     }`)

    const mockRealEstate = {
        sections: {
            tasacion: {
                subtitle: "SALES PUSHING",
                title: ["Sabemos que las operaciones inmobiliarias", "son muy importantes en la vida de las personas."],
                steps: [
                    {
                        id: 1,
                        title: "Tasación VALORATIVA",
                        text1: "No sumamos m2, evaluamos múltiples variables a la hora  de tasar",
                        text2: "Evaluamos hasta los más mínimos detalles para fundamentar con datos sólidos el valor real de una propiedad conociendo profundamente la demanda actual del mercado y comparativos reales de operaciones concretadas."
                    },
                    {
                        id: 2,
                        title: "Ver para QUERER",
                        text1: "Recomendaciones para hacer más atractiva la propiedad en el proces de venta.",
                        text2: "Orientamos al propietario utilizando técnicas de marketing visual, conpautas sencillas que puedencambiar mucho el aspecto de unapropiedad destacando sus puntosfuertes y que los interesados puedanapreciar sus potencialidades. Hoy laimagen es protagonista, olvidarnosde esto nos hará perder oportunidades de venta."
                    },
                    {
                        id: 3,
                        title: "Publicaciones Premium + SMS inmobiliario",
                        text1: "Su propiedad on-line en múltiples plataformas + nuestro exclusivo e innovador sistema de SMS Inmobiliario.",
                        text2: "Incorporamos al inmueble en nuestra base de datos, nuestra web  y los principales portales inmobiliarios con avisos destacados. Además somos la única inmobiliaria del país con el exclusivo sistema  SMS inmobiliario mediante el cual  enviando un SMS gratuito con el código que aparece en el cartel, el interesado  recibirá al instante una breve descripción  de la propiedad en su celular, las 24hs del día, los 7 días de la semana."
                    },
                    {
                        id: 4,
                        title: "Bienvenido a CASA",
                        text1: "Preparación del momento de la visita contemplando particularidades de la propiedad, zona, horario y del potencial comprador.",
                        text2: "Preparar una visita no es sólo combinar un horario. Nuestro equipo de ventas contempla las particularidades de la propiedad, zona, horarios y las necesidades de los visitantes para preparar esa “primera impresión” que les permitirá visualizarse como los próximos habitantes de esos espacios."
                    },
                    {
                        id: 5,
                        title: "Compra / Venta ASISTIDA",
                        text1: "Asesoramiento Permanente aún después de concretada la operación.",
                        text2: "Desde una reserva hasta el cierre de una negociación, la atención es personalizada y con los responsables de la empresa pero nuestro compromiso y asesoramiento no finaliza con la concreción de la operación. Lo acompañamos y ponemos a su disposición nuestros conocimientos y experiencia adquiridos en 30 años de trayectoria en el rubro para atender todas sus dudas y consultas antes, durante y después de la realización de la operación."
                    },
                ],
                img: {
                    src: aboutStepImg,
                    alt: "Bellagamba Tasaciones"
                }
            }
        }
    }

    return (
        <section class="about-sale">
            <div class="container-fluid">
                <h5 class="section d-inline">{mockRealEstate.sections.tasacion.subtitle}</h5>
                <h3>
                    {mockRealEstate.sections.tasacion.title[0]} <br/>
                    {mockRealEstate.sections.tasacion.title[1]}
                </h3>
                <div class="row no-gutters">
                    {mockRealEstate.sections.tasacion.steps.map((step) => (
                        <div class="col-lg col-12 pr-lg-5" key={step.id}>
                            <div class="step">
                                <div class="header-step">
                                    <div class="align-height d-flex align-item-center justify-content-start align-items-end">
                                        <span>{step.id}</span>
                                        <p>{step.title}</p>
                                    </div>
                                    <i class="align-height">
                                        {step.text1}
                                    </i>
                                </div>
                                <div class="body-step">
                                    <p>{step.text2}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <img class="d-none d-lg-block my-lg-5 w-100" 
                    src={mockRealEstate.sections.tasacion.img.src} 
                    alt={mockRealEstate.sections.tasacion.img.alt}/>
            </div>
        </section>
    )
}

export default TasacionSale;