import * as React from "react"
import Layout from "../components/layout"
import BannerTop from "../components/Modules/MediaPush/BannerTop"
import Seo from "../components/seo"

//Components
import Main from '../components/Tasaciones/main'
import TasacionSale from '../components/Tasaciones/tasaciones-sale'

const IndexPage = () => (
  <Layout>
    <Seo title="Tasaciones" description="Aquí debería ir la descripción de la sección de servicios" />
    <BannerTop page="tasaciones"/>
      <Main />
      <TasacionSale />
  </Layout>
)

export default IndexPage
