import React from 'react'
import Formulario from './form'
import Breadcrumbs from '../breadcrumbs';
import { graphql, useStaticQuery } from 'gatsby';

const Main = () => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              sections {
                    services{
                        title
                        subtitle
                        paragraph
                        form
                    }
              }
          }
    }`)

    const mockRealEstate = {
        sections: {
            services: {
                title: ["Conocé el verdadero", "valor de tu propiedad."],
                subtitle: [
                    "Completá el siguiente formulario con los datos", 
                    "básicos de tu propiedad y un agente especializado", 
                    "se pondrá en contacto dentro de las 24hs."
                ]
            }
        }
    }

    return realEstate.sections &&
      realEstate.sections.services.form ? (
        <section class="tasaciones-main">
            <div class="container-fluid">
                <div class="row mt-lg-4 center align-items-center">
                    <div class="col-12">
                        <div class="row content-main align-items-start">
                            <div className="col-12">
                               <Breadcrumbs
                                   props={[
                                   { name: "Home", route: "/", location: "" },
                                   { name: "Tasaciones", route: "", location: ""},
                                   ]}
                               />
                           </div>
                            <div class="col-lg-12">
                                <h1 class="mb-lg-5">
                                    {mockRealEstate.sections.services.title[0]}<br/>
                                    {mockRealEstate.sections.services.title[1]}
                                </h1>
                                <p class="mb-4 mt-5 mt-lg-0">
                                    {mockRealEstate.sections.services.subtitle[0]}<br/>
                                    {mockRealEstate.sections.services.subtitle[1]}<br/>
                                    {mockRealEstate.sections.services.subtitle[2]}
                                </p>
                                <Formulario />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ):''
}

export default Main