import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import {getTotalListFilters} from '../../redux/propertiesDucks'
import { updateFields,formTasaciones_Tokko } from '../../redux/contactDucks'
import toast, { Toaster } from 'react-hot-toast';
import { graphql, useStaticQuery } from 'gatsby';
import ReCAPTCHA from "react-google-recaptcha";
import IconRightSvg from "../../images/svg/icon-right.svg";
import DatePicker,{ registerLocale } from "react-datepicker";
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import SVG from '../../images/svg/calendar.svg';
registerLocale("es", es); // register it with the name you want

const Form = ({totalListFilters,dispatch}) => {
    
    const type = useRef()
    const operation = useRef()
    const [datos, setDatos] = useState({
        nombre:'',
        email:'',
        telefono:'',
        date:'',
        ubicacion:'',
        sup_cub:'',
        sup_tot:'',
        rooms:'',
        garage:'',
        age:'',
        amenities:[]
    })
        
    const [disabled, setDisabled] = useState(true);
    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                keys {
                    captcha
                }
            }
    }`)
                
    const handleInputChange = (event) => {
        if(event.target.name == 'amenities'){
            if(datos.amenities.find(element => element === event.target.value)){
                const newAmenities = datos.amenities.filter(element => element !== event.target.value)
                setDatos({
                    ...datos,
                    [event.target.name] : newAmenities, 
                })
                return null
            }
        setDatos({
            ...datos,
            amenities : [...datos.amenities,event.target.value], 
        })
            return null
        }
        setDatos({
            ...datos,
            [event.target.name] : event.target.value, 
        })
        
    }
    const clearForm= ()=>{
        setDatos({
        nombre:'',
        email:'',
        ubicacion:'',
        sup_cub:'',
        sup_tot:'',
        rooms:'',
        garage:'',
        age:'',
        amenities:[]
        })
    }
    const sendForm = (event) => {
        event.preventDefault()
        dispatch(formTasaciones_Tokko(type.current.value,operation.current.value,getUtm()))
        window.scrollTo(0, 0)
        document.getElementById("formContact").reset()
        return toast.success("¡Mensaje enviado correctamente!") 
    }
    useEffect(() => {
        const update = () => {
            dispatch(updateFields(datos))
        }
        update()
      
    }, [datos])
    useEffect(() => {
        if(!realEstate?.keys?.captcha){
            setDisabled(false)
          }
        else{
            setDisabled(true)
        }
        dispatch(getTotalListFilters())
    },[])
    const getUtm = (customs = []) => {
        if(typeof window !== 'undefined'){
            var queryString = window.location.search
            var urlParams = new URLSearchParams(queryString)
            const tags = [customs]
            for (const [key, value] of urlParams) {
                if(key.includes("source")){tags.push("Origen: " + value)}
                if(key.includes("medium")){tags.push("Pieza: " + value)}
                if(key.includes("campaign")){tags.push("Campaña: " + value)}
            }
            return tags
        }
    }
    return (
        <>
        <form onSubmit={sendForm} id="formContact" class="row content-form ajax-form">
            <div class="col-lg-11">
                <div class="row">
                    <div class="col-lg-6 pr-lg-3 pr-lg-2 mb-4 mb-lg-4">
                        <input onChange={handleInputChange} type="text" required placeholder="Ubicación *" name="ubicacion" id="ubicacion" />
                    </div>
                    <div class="col-lg-6 pr-lg-3 pl-lg-2 mb-4 mb-lg-4 position-relative">
                        <select required ref={operation} name="operation">
                            <option value="">Operación</option>
                            {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 1) ? <option value="Venta">Venta</option> : ''}
                            {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 2) ? <option value="Alquiler">Alquiler</option> : ''}
                            {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 3) ? <option value="Alquiler Temporario">Alquiler Temporario</option> : ''}
                        </select>
                        <i></i>
                    </div>
                    <div class="col-lg-6 pr-lg-3 mb-4 mb-lg-4 position-relative">
                        <select ref={type} name="type">
                            <option value="">Tipo de propiedad</option>
                            {totalListFilters.data ? 
                                totalListFilters.data.objects.property_types.map((item,index) => (
                                    <option value={item.type}>{item.type}</option>
                                    )
                                )
                                :''
                            }
                        </select>
                        <i></i>
                    </div>
                    <div class="col-12 pr-3 camp">
                        <div class="row">
                            <div class="col-lg-6">
                            <label> Ambientes</label>
                                <div class="ticks ticks-amb d-flex justify-content-between align-items-center">
                                    <span class="option">
                                        <input onChange={handleInputChange} type="radio" id="amb-1" name="rooms" value="1" />
                                        <label for="amb-1" class="mb-0"><span class="square">1</span></label>
                                    </span>  
                                    <span class="option">
                                        <input onChange={handleInputChange} type="radio" id="amb-2" name="rooms" value="2" />
                                        <label for="amb-2" class="mb-0"><span class="square">2</span></label>
                                    </span>  
                                    <span class="option">
                                        <input onChange={handleInputChange} type="radio" id="amb-3" name="rooms" value="3" />
                                        <label for="amb-3" class="mb-0"><span class="square">3</span></label>
                                    </span>  
                                    <span class="option">
                                        <input onChange={handleInputChange} type="radio" id="amb-4" name="rooms" value="4" />
                                        <label for="amb-4" class="mb-0"><span class="square">4</span></label>
                                    </span>  
                                    <span class="option">
                                        <input onChange={handleInputChange} type="radio" id="amb-5" name="rooms" value="5" />
                                        <label for="amb-5" class="mb-0"><span class="square">5</span></label>
                                    </span>  
                                    <span class="option">
                                        <input onChange={handleInputChange} type="radio" id="amb-6" name="rooms" value="6" />
                                        <label for="amb-6" class="mb-0"><span class="square">+</span></label>
                                    </span> 
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div class="col-6 pr-3 py-3 py-lg-0 camp">
                        <input onChange={handleInputChange} type="text" name="sup_cub" placeholder="Superficie Cubierta (m2)"  />
                    </div>
                    <div class="col-6 pl-3 py-3 py-lg-0 camp">
                        <input onChange={handleInputChange} type="text" name="sup_tot" placeholder="Superficie Total (m2)"  />
                    </div>
                    <div class="col-lg-6 camp">
                    <label>Antigüedad</label>
                        <div class="ticks row d-flex justify-content-between align-items-center">
                            <div class="col-6">
                                <span class="option option-check">
                                    <input onChange={handleInputChange} type="radio" id="age-1" name="age" value="A estrenar" />
                                    <label for="age-1" class="mb-0 me-0"><span class="square"></span>A estrenar</label>
                                </span> 
                            </div>
                            <div class="col-6">
                                <span class="option option-check">
                                    <input onChange={handleInputChange} type="radio" id="age-2" name="age" value="Hasta 10 años" />
                                    <label for="age-2" class="mb-0 me-0"><span class="square"></span>Hasta 10 años</label>
                                </span> 
                            </div>
                            <div class="col-6 mt-3">
                                <span class="option option-check">
                                    <input onChange={handleInputChange} type="radio" id="age-3" name="age" value="10 - 50 años" />
                                    <label for="age-3" class="mb-0 me-0"><span class="square"></span>10 - 50 años</label>
                                </span>  
                            </div>
                            <div class="col-6 mt-3">
                                <span class="option option-check">
                                    <input onChange={handleInputChange} type="radio" id="age-4" name="age" value="+50 años" />
                                    <label for="age-4" class="mb-0 me-0"><span class="square"></span>+50 años</label>
                                </span>  
                            </div>

                        </div>	
                    </div>
                    <div class="col-6 camp d-none d-lg-block">
                    <label>Garage</label>
                        <div class="ticks d-flex justify-content-start align-items-center">
                            <span class="option option-check me-5">
                                <input onChange={handleInputChange} type="radio" id="garage-1" name="garage" value="Sí" />
                                <label for="garage-1" class="mb-0"><span class="square"></span>Sí</label>
                            </span>  
                            <span class="option option-check me-5">
                                <input onChange={handleInputChange} type="radio" id="garage-2" name="garage" value="No" />
                                <label for="garage-2" class="mb-0"><span class="square"></span>No</label>
                            </span>  
                        </div>	
                    </div>
                    <div class="col-12 camp mt-4 mt-lg-0">
                    <label class="mb-4">Amenities</label>
                        <div class="mt-2 ticks row align-items-start justify-content-between justify-content-lg-start">
                            <div class="col-lg-3 col-6">
                                <span class="option option-check">
                                    <input onChange={handleInputChange} type="checkbox" id="SUM" name="amenities" value="SUM" />
                                    <label for="SUM"><span class="square"></span>SUM</label>
                                </span>
                            </div>
                            <div class="col-lg-3 col-6">
                                <span class="option option-check">
                                    <input onChange={handleInputChange} type="checkbox" id="Laundry" name="amenities" value="Laundry" />
                                    <label for="Laundry"><span class="square"></span>Laundry</label>
                                </span>
                            </div>
                            <div class="col-lg-3 col-6">
                                <span class="option option-check">
                                    <input onChange={handleInputChange} type="checkbox" id="Pileta" name="amenities" value="Pileta" />
                                    <label for="Pileta"><span class="square"></span>Pileta</label>
                                </span>
                            </div>
                            <div class="col-lg-3 col-6">
                                <span class="option option-check">
                                    <input onChange={handleInputChange} type="checkbox" id="Gym" name="amenities" value="Gym" />
                                    <label for="Gym"><span class="square"></span>Gym</label>
                                </span>
                            </div>
                            <div class="col-lg-3 col-6">
                                <span class="option option-check">
                                    <input onChange={handleInputChange} type="checkbox" id="Parrilla" name="amenities" value="Parrilla" />
                                    <label for="Parrilla"><span class="square"></span>Parrilla</label>
                                </span>
                            </div>
                        </div>	
                    </div>
                    <div class="col-lg-6  camp mb-4">
                        <input onChange={handleInputChange} type="text" required name="nombre"  placeholder="Nombre y Apellido *" />
                    </div>
                    <div class="col-lg-6 camp">
                        <input onChange={handleInputChange} type="text" required name="email"  placeholder="E-Mail *" />
                    </div>
                    <div class="col-lg-6 camp">
                        <input onChange={handleInputChange} type="text" required name="telefono"  placeholder="Teléfono *" />
                    </div>
                    <div className="col-lg-6"></div>
                    <div class="col-lg-6 camp mt-4 mt-lg-0">
                        <label class="mb-2">Visita a la propiedad</label>
                        <p>Indíquenos que dia le es conveniente para que un especialista visite la propiedad.</p>
                        <div className="both position-relative">
                            <DatePicker dateFormat="dd/MM/yyyy" selected={datos.date} onChange={(date) => handleInputChange({target: {name:'date', value:date}})}  locale="es" />
                            <div className="calendary-icon"><SVG /></div>
                        </div>
                    </div>
                    <div class="col-lg-12 camp">
                        <div className="d-lg-flex w-100">
                        {
                            realEstate?.keys?.captcha ? 
                            <ReCAPTCHA
                            sitekey={realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0}
                            onChange ={() => setDisabled(false)}
                            /> 
                            : 
                            '' 
                        }
                        </div>
                        <button disabled={disabled} type="submit" id="submit-info"
                            class="btn d-flex submit justify-content-start align-items-center mt-4">
                            CONTACTANOS <label for="subit-info"><IconRightSvg class="icon"/></label>
                        </button>
                        <p class="mt-3 mt-5">*Campos obligatorios</p>
                    </div>
                </div>
            </div>
        </form>
        <div id="alert-message"></div>
    </>
    )
}

export default connect(state => ({
    totalListFilters: state.properties.totalListFilters,
}),null)(Form);